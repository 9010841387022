<template>
    <div id="ExtendDetail"> 
        <Header/>      
        <body class=" bg-white">
            <!-- header -->
            <nav class="navbar bg-white fixed-top nicole-noshadow">
                <div class="container">
                    <a class="navbar-brand font-weight-bolder me-0 col-4" rel="tooltip" data-placement="bottom" @click="onBack">
                        <img class="avatar avatar-sm" src="assets/img/icon-back-black.png">
                    </a>            
                    <a class="navbar-brand font-weight-bold me-0 col-4 text-center" rel="tooltip" data-placement="bottom">
                        延伸新知
                    </a>             
                    <a class="navbar-brand font-weight-bolder me-0 text-right col-4" >
                    </a> 
                </div>
            </nav>

            <div class="nicolespacetop"></div>

            <!-- 課程圖片 -->
            <div div class="mb-3" v-show="base64_url.length > 0">
                <img v-real-img="base64_url" src="assets/img/noback.png" class="newspic">
            </div>
            <div class="container">
                <!-- 課程標題 -->
                <div class="mb-3" >
                    <!-- eslint-disable vue/no-v-html -->
                    <h5 class="fw-bolder text-22 text-dark" v-html="name"></h5>
                    <!--eslint-enable-->
                </div>
                <div class="mb-3">
                    <!-- eslint-disable vue/no-v-html -->
                    <h5 class="text-gray text-mm" v-html="explanation"></h5>
                    <!--eslint-enable-->
                </div>

                <!-- 課程內文 所見即所得 -->
                <!-- eslint-disable vue/no-v-html -->
                <div class="mb-3" v-html="description"></div>
                <!--eslint-enable-->



                <div class="nicolespacetop"></div>

                <div class="mb-4">
                    <h5 class="text-dark text-bold mt-2 mb-3 text-18">單字列表</h5>
                    <div v-for="(item, index) of lesson_word_sentence_speech" :key="index">
                        <div class="">
                            <div class="d-flex align-content-center justify-content-between">
                                <h5 class="text-success text-bolder text-18">{{item.lesson_word_name}}</h5>      
                                <img class="avatar avatar-sm rounded-0 mt-1" :src="lastIndex == index ? playAudio : noPlayAudio" @click="textToSpeech(item.lesson_word_name, index)" > 
                            </div>    
                            <h5 class="text-gray text-s">{{item.lesson_word_phonetic}}</h5>
                            <h5 class="text-dark text-s">{{item.speech_code}}&nbsp;{{item.speech_name}}</h5>
                        </div>   
                        <hr v-if="index < lesson_word_sentence_speech.length-1"> 
                    </div>
                </div>
            </div>
            <div class="nicolespace"></div>
            <div class="nicolespace"></div>
        </body>
        <Footer/>
    </div>
</template>


<script>
import Vue from 'vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import { oltConsole , oAC } from "@/lib/oltModule/initClient";
import { config } from "@/include/config"; 

export default {
    name: 'ExtendDetail',
    components: {
        Header,
        Footer,
    },
    data () {
        return { 
            // api setting
            setDebugMode:false ,
            apiDebug: false,
            perPage: 10,           //預設10筆
            apiName: "page_extend_detail",
            pageMode: true,
            currentPage: 0,
            totalPage: 0,
            act: "",
            form: [],
			 config:"",

            // data
            lsid: "",
            name: "",
            explanation: "",
            description: "",
            base64_url: "",
            backUrl: "",
            lastIndex: -1,

            lesson_word_sentence_speech: [],

            playAudio: "assets/img/ic_loudspeaker_playing.png",
            noPlayAudio: "assets/img/ic_loudspeaker.png",

            isLoading: false,
        }
    },
    methods: {
        setOnBack(){
            this.$JsBridge.callHandler('setOnback', '', this.onBack);
        },
        onBack(){
            if (this.backUrl != "") {
                this.$router.push({
                    name : this.backUrl,
                });
            } else {
                this.$router.push({
                    name : 'Home',
                });
            }
        },
        getExtendDetail(){
            oAC.getAllRecord(oAC.getApiParam(this), {lsid: this.lsid}).then(res => {
                    if(res != null && res.lesson != null && res.lesson.length > 0){
                        this.name = res.lesson[0].name;
                        this.explanation = res.lesson[0].explanation.replace('t</strong>', 't</strong> ').replace('t</i>', 't</i> ');
                        this.description = res.lesson[0].description.replace('t</strong>', 't</strong> ').replace('t</i>', 't</i> ');
                    }
                    if(res != null && res.lesson_image_list_rt != null && res.lesson_image_list_rt.length > 0){
					
						if(config && config.old_images && config.old_images=="Y") 
						{  	 
							this.base64_url = "data:image/jpeg;base64, " + res.lesson_image_list_rt[0].base64_url;
						}
						else
						{ 
							this.base64_url = "" + res.lesson_image_list_rt[0].base64_url;
						}
                    }

                    if(res != null && res.lesson_word_sentence_speech != null && res.lesson_word_sentence_speech.length > 0){
                        this.lesson_word_sentence_speech = res.lesson_word_sentence_speech;
                    }
                    // 記錄點擊數
                    this.$JsBridge.callHandler('getVueNativeAppVersion', "", this.postHistoryEventLessonList);
                }
            )
            .catch((err)=> {
                oltConsole.log(err);
            });
        },
        textToSpeech(name, index){
            oltConsole.log("textToSpeech");
            oltConsole.log("name: " + name);
            oltConsole.log("index: " + index);
            
            if (name == "") {
                oltConsole.log("Error: name is empty!");
                return;
            }
            if (this.isLoading) {
                oltConsole.log("Error: get API ing!");
                return;
            }

            this.isLoading = true;
            this.lastIndex = index;
            this.$JsBridge.callHandler('textToSpeech', name, this.textToSpeechCallback);  
        },
        textToSpeechCallback(){
            this.lastIndex = -1;
            this.isLoading = false;
        },
        postHistoryEventLessonList(version){
            // console.log("postHistoryEventLessonList version => ", version);
            if (version != undefined && version != "") {            
                this.apiName = 'history_event_lesson_list';
                this.act = "post";
                let data = {};
                if (this.$GlobalData.uid != undefined && this.$GlobalData.uid != "") {
                    data["uid"] = this.$GlobalData.uid;
                } else {
                    data["uid"] = 0;
                }
                data["lsid"] = this.lsid;
                if(this.$JsBridge.isiOS()){
                    data["device"] = "ios";
                } else if (this.$JsBridge.isAndroid()){
                    data["device"] = "android";
                } else {
                    data["device"] = "pc";
                }
                data["model"] = "phone";
                data["version"] = version;
                // console.log("postHistoryEventLessonList data => ", JSON.stringify(data));
                let apiParam = oAC.getApiParam(this);
                apiParam.form = data;
                // console.log("postHistoryEventLessonList apiParam.form => ", JSON.stringify(apiParam.form));
                oAC.postRecord(apiParam, {}).then(res => {

                })
                .catch((err)=> {
                    oltConsole.log(err);
                });
            }
        },
        JumpToTop(){
            // 自動將畫面移動到最上方
            $("html, body").animate({ scrollTop: $("html").offset().top }, {duration: 500,easing: "swing"});
        },
        openWebVue(url){
            this.$JsBridge.callHandler('openWebVue',url, this.openWebVueCallback);
        },
        openWebVueCallback(){
            
        }
    },
    created(){
        if (this.$JsBridge.isAndroid) {
            this.setOnBack();
        }
        if (this.$route.query.lsid != undefined) {
            this.lsid = this.$route.query.lsid;
        } else if (this.$route.params.lsid != undefined) {
            this.lsid = this.$route.params.lsid;
        }

        if (this.$route.query.backUrl != undefined) {
            this.backUrl = this.$route.query.backUrl;
        } else if (this.$route.params.backUrl != undefined) {
            this.backUrl = this.$route.params.backUrl;
        }
        this.JumpToTop();
        this.getExtendDetail();
        window["openWeb"] = this.openWebVue;
    },
    mounted () {
		this.config = config;
      window.openWebVue = this.openWebVue;
    }
};
</script>
<style scoped>
    /deep/ body{
        color: #333;
    }
    /deep/ a{
        color: #12bbad;
        text-decoration: none;
        background-color: transparent;
    }
    /deep/ span{
        word-wrap: break-word;
    }
    /deep/ .newspic{
        width: 100vw;
        height: 56vw;
        object-fit: cover;
    }
</style>